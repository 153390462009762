// @flow

import * as React from 'react';
import { graphql } from 'gatsby';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';

import SectionWrapper from '../components/SectionWrapper';
import PageHelmet from '../plugins/helmet/components/PageHelmet';
import BlogArchive from '../components/BlogArchive';
import type { Query } from '../schema.flow';

const styles = () => ({
  root: {},
  text: {
    textTransform: 'uppercase',
  },
});

export type Props = {
  classes: { [$Keys<$Call<typeof styles>>]: string },
  data: Query,
};

const ArchivePage = ({ classes, data: query }: Props) => (
  <SectionWrapper className={classes.root}>
    <PageHelmet data={query.prismicBlogArchive?.data} />
    <SectionWrapper limitWidth guttersH guttersV>
      {query.prismicBlogArchive?.data?.title?.text ? (
        <Typography variant="h1" gutterBottom className={classes.text}>
          {query.prismicBlogArchive?.data?.title?.text}
        </Typography>
      ) : null}
      {query.allPrismicBlogEntry && query.allPrismicBlogEntry.edges.length ? (
        <BlogArchive
          nodes={query.allPrismicBlogEntry.edges.map(({ node }) => node)}
        />
      ) : null}
    </SectionWrapper>
  </SectionWrapper>
);

export default withStyles(styles)(ArchivePage);

export const query = graphql`
  query ArchivePageQuery {
    prismicBlogArchive {
      id
      lang
      data {
        meta_title {
          text
        }
        meta_description {
          text
        }
        meta_keywords {
          meta_keyword {
            text
          }
        }
        title {
          text
          html
        }
      }
    }
    allPrismicBlogEntry(
      sort: { fields: [first_publication_date], order: DESC }
    ) {
      edges {
        node {
          id: prismicId
          first_publication_date
          data {
            title {
              text
            }
            image {
              localFile {
                id
                childImageSharp {
                  fluid(maxHeight: 250, quality: 82) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
