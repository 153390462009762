// @flow

import * as React from 'react';
import { withStyles } from '@material-ui/core/styles';
import type { Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import groupBy from 'lodash/groupBy';
import map from 'lodash/map';

import BlogArchiveGrid from './BlogArchiveGrid';
import formatBlogEntryDate from '../../helpers/formatBlogEntryDate';
import parseDate from '../../helpers/parseDate';

import type { PrismicBlogEntry } from '../../schema.flow';

const styles = (theme: Theme) => ({
  root: {
    padding: `${theme.spacing.unit * 6}px 0px`,
  },
  entriesGroup: {
    paddingBottom: theme.spacing.unit * 8,
  },
  text: {
    textTransform: 'uppercase',
    paddingBottom: theme.spacing.unit * 4,
  },
});

export type Props = {
  nodes: ?Array<PrismicBlogEntry>,
  classes: { [$Keys<$Call<typeof styles, Theme>>]: string },
};

const BlogArchive = ({ classes, nodes: allNodes }: Props) => {
  const { __NULL__: unusedGroup, ...groups } = groupBy<string, *, *>(
    allNodes || [],
    node => {
      const date =
        node && node.first_publication_date
          ? parseDate(node.first_publication_date)
          : null;
      return date ? formatBlogEntryDate({ date, yearFirst: true }) : '__NULL__';
    },
  );

  return (
    <div className={classes.root}>
      {map<
        Array<PrismicBlogEntry>,
        *,
        {| nodes: Array<PrismicBlogEntry>, key: string |},
      >(groups, (nodes, key) => ({
        nodes,
        key,
      }))
        .sort((a, b) => a.key.localeCompare(b.key) * -1)
        .map(({ nodes, key }) => (
          <React.Fragment key={key}>
            <div className={classes.entriesGroup}>
              <Typography variant="h5" className={classes.text}>
                {key
                  .split('.')
                  .reverse()
                  .join('.')}
              </Typography>
              <BlogArchiveGrid nodes={nodes} />
            </div>
          </React.Fragment>
        ))}
    </div>
  );
};

export default withStyles(styles)(BlogArchive);
