// @flow

import * as React from 'react';
import { withStyles } from '@material-ui/core/styles';
import type { Theme } from '@material-ui/core/styles';
import ButtonBase from '@material-ui/core/ButtonBase';
import Typography from '@material-ui/core/Typography';
import Image from 'gatsby-image';

import Link from '../../plugins/i18n/components/Link';
import type { PrismicBlogEntry } from '../../schema.flow';

const styles = (theme: Theme) => ({
  root: {
    width: '100%',
    '&:hover $text': {
      opacity: 1,
    },
  },
  wrapper: {},
  imageContainer: {
    position: 'relative',
    width: '100%',
  },
  image: {
    opacity: 0.6,
    width: '300px',
    height: '300px',
    transitionProperty: 'opacity',
    transitionDuration: `${theme.transitions.duration.standard * 2}ms`,
    transitionTimingFunction: theme.transitions.easing.easeInOut,
    [theme.breakpoints.down('xs')]: {
      width: '120px',
      height: '120px',
    },
  },
  textContainer: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    textAlign: 'center',
    padding: theme.spacing.unit * 3,
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing.unit,
    },
  },
  text: {
    opacity: 0.7,
    textTransform: 'uppercase',
    transitionProperty: 'opacity',
    transitionDuration: `${theme.transitions.duration.standard * 2}ms`,
    transitionTimingFunction: theme.transitions.easing.easeInOut,
    [theme.breakpoints.down('sm')]: {
      opacity: 1,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '1rem',
    },
  },
});

export type Props = {
  node: ?PrismicBlogEntry,
  classes: { [$Keys<$Call<typeof styles, Theme>>]: string },
};

const BlogArchivePost = ({ classes, node }: Props) =>
  node ? (
    <Link to={`newsletter/${node.id}`}>
      <ButtonBase
        aria-label="Archive post"
        disableRipple
        className={classes.root}
      >
        <div className={classes.wrapper}>
          <div className={classes.imageContainer}>
            {node?.data?.image?.localFile?.childImageSharp ? (
              <Image
                {...node?.data?.image?.localFile?.childImageSharp}
                className={classes.image}
              />
            ) : null}
            <div className={classes.textContainer}>
              {node?.data?.title?.text ? (
                <Typography
                  variant="h3"
                  color="textPrimary"
                  className={classes.text}
                >
                  {node?.data?.title?.text}
                </Typography>
              ) : null}
            </div>
          </div>
        </div>
      </ButtonBase>
    </Link>
  ) : null;

export default withStyles(styles)(BlogArchivePost);
