// @flow

import * as React from 'react';
import { withStyles } from '@material-ui/core/styles';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import type { Breakpoint } from '@material-ui/core/styles/createBreakpoints';
import type { HOC } from 'recompose';
import compose from 'recompose/compose';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';

import BlogArchivePost from './BlogArchivePost';
import type { PrismicBlogEntry } from '../../schema.flow';

const styles = () => ({
  root: {
    width: '100%',
    height: '100%',
  },
});

export type Props = {
  width: Breakpoint,
  nodes: ?Array<PrismicBlogEntry>,
  classes: { [$Keys<$Call<typeof styles>>]: string },
};

const BlogArchiveGrid = ({ classes, nodes, width }: Props) =>
  nodes ? (
    <GridList
      spacing={10}
      cellHeight="auto"
      cols={isWidthDown('sm', width) ? 2 : 3}
      className={classes.root}
    >
      {nodes &&
        nodes.map(node =>
          node &&
          node?.data?.title?.text &&
          node?.data?.image?.localFile?.childImageSharp ? (
            <GridListTile key={node.id} cols={1}>
              <BlogArchivePost node={node} />
            </GridListTile>
          ) : null,
        )}
    </GridList>
  ) : null;

const enhancer: HOC<
  Props,
  {
    ...$Exact<$Diff<Props, { width: any, classes: any }>>,
    classes?: $Shape<$ElementType<Props, 'classes'>>,
  },
> = compose(
  withWidth(),
  withStyles(styles),
);

export default enhancer(BlogArchiveGrid);
